@import "~antd/lib/style/themes/default.less";

.timeline {
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 6px;

    &.orange {
        background-color: @orange-1;
    }

    &.cyan {
        background-color: @cyan-1;
    }

    &.blue {
        background-color: @blue-1;
    }

    &.green {
        background-color: @green-1;
    }

    &.red {
        background-color: @red-1;
    }

    .createdAt {
        font-size: 12px;
        cursor: default;
    }

    .number {
        cursor: pointer;
    }

    &__point {
        .ant-timeline-item-head {
            &-blue {
                border-color: @blue-5;
            }
        }
    }
}
